/**
 * @todo Add support for fractional items (e.g. 1.5)
 * @param { number } choice a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param { number } choicesLength an overall amount of available choices
 * @returns a final choice index to select plural word by
 */
export function CzechAndSlovakPluralization(choice: number, choicesLength: number) {
    // 4 choices    example: 'dní | den | dny | dní'
    if (choicesLength === 4) {
        // 0 items  example: '0 dní'
        if (choice === 0) {
            return 0
        }

        // single item  example: '1 den' or '-1 den'
        if (choice === 1 || choice === -1) {
            return 1
        }

        // 2-4 items    example: '2 dny | 3 dny | 4 dny | -2 dny | -3 dny | -4 dny'
        if ((choice >= 2 && choice <= 4) || (choice >= -4 && choice <= -2)) {
            return 2
        }

        // 5+ items     example: '5 dní'
        return 3

    } else if (choicesLength === 3) { // 3 choices    example: 'den | dny | dní'
        // 0 items  example: '0 dní'
        if (choice === 0) {
            return 2
        }

        // single item  example: '1 den' or '-1 den'
        if (choice === 1 || choice === -1) {
            return 0
        }

        // 2-4 items    example: '2 dny | 3 dny | 4 dny | -2 dny | -3 dny | -4 dny'
        if ((choice >= 2 && choice <= 4) || (choice >= -4 && choice <= -2)) {
            return 1
        }

        // 5+ items     example: '5 dní'
        return 2
    } else if (choicesLength === 2) { // For localizing currencies
        return choice - 1
    } else {
        throw new Error('CzechAndSlovakPluralization: unsupported amount of choices')
    }
}
